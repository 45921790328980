import React from 'react'
import { FaRulerCombined, FaCircleNotch, FaWeight } from 'react-icons/fa'

const CardSize = ({ length, circumference, weight }) => {
  const lengthComponent = (
    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
      <FaRulerCombined className='icon' /> {length}&nbsp;cm
    </span>
  )

  const diameterComponent = (
    <span
      style={{ marginLeft: '10px', marginRight: '10px', whiteSpace: 'nowrap' }}
    >
      <FaCircleNotch className='icon' /> {circumference}&nbsp;cm
    </span>
  )

  const weightComponent = (
    <span style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>
      <FaWeight className='icon' /> {weight}&nbsp;kg
    </span>
  )

  return (
    <div>
      <span>{length ? lengthComponent : null}</span>
      <span>{circumference ? diameterComponent : null}</span>
      <span>{weight ? weightComponent : null}</span>
    </div>
  )
}

export default CardSize
