import React, { Component } from 'react'
import { Col, Button, Modal } from 'antd'
import Title from 'antd/lib/typography/Title'
import { DatePicker, message, Form } from 'antd'
import moment from 'moment'
import { map } from './EventsMap'
import { LatLongHMS, LatLongDD, LatLongResult } from './LatLong'
import { coordsHMSToDecimal, coordsDecimalToHMS } from '../../utils'

const defaultLatLong = [43.737394, 7.435849]

export default class EventInformation extends Component {
  state = {
    datetime: moment(),
    errorMessage: '',

    latitude: defaultLatLong[0],
    longitude: defaultLatLong[1],

    date: '',
    time: '',
    modalSetPosition: false,
    modalVisible: false
  }

  setPositionFieldFromHMS = (hmsLat, hmsLon) => {
    const [ddLat, ddLon] = coordsHMSToDecimal(hmsLat, hmsLon)
    if (ddLat === -1 || ddLon === -1) {
      message.error('Erreur lors de la conversion des coordonnées')
      return
    }
    this.props.setFieldValues({
      latitudeHMS: hmsLat,
      longitudeHMS: hmsLon,
      latitudeDD: ddLat,
      longitudeDD: ddLon,
      latitude: ddLat,
      longitude: ddLon
    })
    this.setState({ latitude: ddLat, longitude: ddLon }, () => {
      console.log('setted state', this.state, ddLat, ddLon)
    })
  }

  setPositionFieldFromDD = (ddLat, ddLon) => {
    const [hmsLat, hmsLon] = coordsDecimalToHMS(ddLat, ddLon)
    this.props.setFieldValues({
      latitudeHMS: hmsLat,
      longitudeHMS: hmsLon,
      latitudeDD: ddLat,
      longitudeDD: ddLon,
      latitude: ddLat,
      longitude: ddLon
    })
    this.setState({ latitude: ddLat, longitude: ddLon })
  }

  onModalClick = ({ event, latLng, pixel }) => {
    if (!latLng) return null
    if (this.state.modalSetPosition) {
      this.setPositionFieldFromDD(latLng[0], latLng[1])
    }
  }

  getLocation = () => {
    if (navigator.geolocation) {
      // doit etre servi par HTTPS pour fonctionner
      navigator.geolocation.getCurrentPosition(
        this.setPosition,
        e => message.error(e.message),
        { maximumAge: 0, enableHighAccuracy: true }
      )
    } else {
      message.error("La géolocalisation n'est pas disponible sur ce navigateur")
    }
  }

  setPosition = position => {
    this.setPositionFieldFromDD(
      position.coords.latitude,
      position.coords.longitude
    )
  }

  onChangeDateTime = momentDate => {
    this.setState({ date: momentDate.format('YYYY-MM-DD') })
    this.setState({ time: momentDate.format('HH:mm') })
  }

  componentDidMount () {
    this.onChangeDateTime(moment())
  }

  render () {
    const viewText = this.props.isCapture ? 'la capture' : "l'observation"
    return (
      <>
        <Modal
          title='Carte'
          cancelText={null}
          closable={false}
          visible={this.state.modalVisible}
          onOk={e => this.setState({ modalVisible: false })}
          footer={[
            <Button
              key='submit'
              type='primary'
              onClick={e => this.setState({ modalVisible: false })}
            >
              Terminé
            </Button>
          ]}
        >
          {map(
            [
              this.state.latitude || defaultLatLong[0],
              this.state.longitude || defaultLatLong[1]
            ],
            12,
            [],
            undefined,
            undefined,
            this.onModalClick,
            [[this.state.latitude, this.state.longitude]]
          )}
        </Modal>
        <Col xs={24}>
          <Title level={2}>Information sur {viewText}</Title>
          <div>
            Nous ne divulgeons pas les informations aux autres pêcheurs.
          </div>
        </Col>

        <Col xs={24} md={24}>
          <Title level={3}>Date et heure de {viewText}</Title>
          <Form.Item name='datetime'>
            <DatePicker
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              size='large'
              onChange={momentDate => this.onChangeDateTime(momentDate)}
              placeholder={`Date et heure de ${viewText}`}
            />
          </Form.Item>
        </Col>

        <LatLongHMS
          key={`hms-${this.state.latitude}-${this.state.longitude}`}
          onChange={this.setPositionFieldFromHMS}
          latitude={this.state.latitude}
          longitude={this.state.longitude}
        />
        <LatLongDD
          key={`dd-${this.state.latitude}-${this.state.longitude}`}
          onChange={this.setPositionFieldFromDD}
          latitude={this.state.latitude}
          longitude={this.state.longitude}
        />
        <LatLongResult
          key={`truth-${this.state.latitude}-${this.state.longitude}`}
        />

        <Button
          onClick={e => {
            this.setState({ modalSetPosition: true })
            this.setState({ modalVisible: true })
          }}
        >
          Voir sur une carte
        </Button>
      </>
    )
  }
}
