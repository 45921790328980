import React, { useState } from 'react'
import Title from 'antd/lib/typography/Title'
import ObservationCard from '../shared/ObservationCard'
import { Row, Button, Empty } from 'antd'
import { Redirect, Link } from 'react-router-dom'

function EmptyObservation ({ loading, observations }) {
  if (loading) {
    return null
  }

  if (observations.length > 0) {
    return null
  }

  return (
    <Empty
      image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
      imageStyle={{
        height: 60
      }}
      description={<span>Aucune observation effectuée</span>}
    >
      <Link to='/new-observation'>
        <Button key='2' type='primary' id='new-tag'>
          Nouvelle observation
        </Button>
      </Link>
    </Empty>
  )
}

const MemberObservations = ({ allSpecies, member, loading, update }) => {
  const [redirect, setRedirect] = useState(false)
  const observations = loading ? [1, 1, 1] : member.observations || []

  if (redirect) {
    return <Redirect push to='/observations' />
  }
  return (
    <section>
      <Title level={2}>Observations</Title>

      <EmptyObservation
        observations={observations}
        loading={loading}
      ></EmptyObservation>

      <div className='site-card-wrapper'>
        <Row gutter={[16, 16]}>
          {observations.map((observation, index) => {
            return (
              <ObservationCard
                loading={loading}
                allSpecies={allSpecies}
                key={index}
                {...observation}
                isPublic={false}
                member={member}
                originalObservation={observation}
                update={update}
              ></ObservationCard>
            )
          })}
        </Row>
      </div>

      {observations.length > 0 ? (
        <div className='flex-container'>
          <Button onClick={() => setRedirect(true)}>Vos observations</Button>
        </div>
      ) : null}
    </section>
  )
}

export default MemberObservations
