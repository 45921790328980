import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import Navigation from '../shared/Navigation'
import FormProfilePicture from './FormProfilePicture'
import FormProfileEmail from './FormProfileEmail'
import FormProfileInfos from './FormProfileInfos'
import FormProfileContact from './FormProfileContact'
import { Space } from 'antd'

class ProfilePage extends Component {
  navigation = () => {
    return <Navigation title={'Profil'}></Navigation>
  }

  render () {
    return (
      <BasePage myNavigation={this.navigation()}>
        <div className='flex-container'>
          <Space
            direction='vertical'
            size='large'
            className='flex-container fullpage'
          >
            <div>
              Vous pouvez modifier vos informations personnelles sur cette page.
            </div>
            <FormProfilePicture
              member={this.props.member}
              update={this.props.update}
            ></FormProfilePicture>
            <FormProfileEmail
              member={this.props.member}
              update={this.props.update}
            ></FormProfileEmail>
            <FormProfileInfos
              member={this.props.member}
              update={this.props.update}
            ></FormProfileInfos>
            <FormProfileContact
              member={this.props.member}
              update={this.props.update}
            ></FormProfileContact>
          </Space>
        </div>
      </BasePage>
    )
  }
}

export default ProfilePage
