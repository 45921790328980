import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import { Space } from 'antd'
import Navigation from '../shared/Navigation'
import NewObservationForm from './NewObservationForm'
import { onlyAttributedTags } from '../../utils'
import { Redirect } from 'react-router-dom'

class NewObservationPage extends Component {
  navigation = () => {
    return <Navigation title={'Nouvelle observation'}></Navigation>
  }

  onlyAttributedTagsId = () => {
    const ids = onlyAttributedTags(this.props.member.tags).map(t => t.id)
    return ids
  }

  render () {
    if (this.props.redirect) {
      const redirect = this.props.redirect
      this.props.redirectDone()
      return <Redirect to={redirect} />
    }

    return (
      <BasePage myNavigation={this.navigation()} user={this.props.user}>
        <Space
          direction='vertical'
          size='large'
          className='fullpage'
          style={{ width: '100%' }}
        >
          <div className='text-center'>
            Vous pouvez remplir ce formulaire hors connexion, il se
            synchronisera à votre retour sur terre.
          </div>
          <NewObservationForm
            update={this.props.update}
            selectedTagId={this.props.tagId}
            tagIds={this.onlyAttributedTagsId()}
            species={this.props.species}
            member={this.props.member}
          ></NewObservationForm>
        </Space>
      </BasePage>
    )
  }
}

export default NewObservationPage
