import React from 'react'
import { Select, Form } from 'antd'

const { Option } = Select

export default function TagId (props) {
  const { selectedTagId, tagIds, onSelect } = props
  return (
    <Form.Item
      name='id'
      rules={[
        {
          required: true,
          message: "Renseignez le numéro du tag s'il vous plaît"
        }
      ]}
    >
      <Select
        showSearch
        size='large'
        style={{ width: 250 }}
        placeholder='Selectionnez le tag posé'
        optionFilterProp='children'
        defaultValue={selectedTagId}
        onSelect={onSelect}
      >
        {tagIds.map((tagId, index) => {
          return (
            <Option key={index} value={tagId}>
              {tagId}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}
