import defaultObservation from './img/observation_default.png'
import defaultAvatar from './img/avatar_default.png'
import formatcoords from 'formatcoords'
import parseDMS from 'parse-dms'
import * as Sentry from '@sentry/browser'

export const humanizeSpecies = (species, scientificName) => {
  species = species || []
  const theSpecies = species.filter(
    s => s.scientific_name === scientificName
  )[0]
  if (theSpecies) {
    return theSpecies.vernacular_name
  }
  return null
}

export const requireImage = (path, domain) => {
  let res = ''

  try {
    res = require(`./img/${path}`)
  } catch {
    if (domain === 'avatar') {
      res = defaultAvatar
    } else {
      res = defaultObservation
    }
  }
  return res || defaultObservation
}

export const formatDateTime = (date, time) => {
  let res = ''
  if (date && time) res = `${date} - ${time}`
  return res
}

export const coordsDecimalToHMS = (lat, long) => {
  lat = parseFloat(lat)
  long = parseFloat(long)
  let coords = ''
  try {
    coords = formatcoords([long, lat], true).format('DD MM X', {
      latLonSeparator: '|||||',
      decimalPlaces: 0
    })
  } catch (e) {
    Sentry.captureException(e)
    return [-1, -1]
  }
  return coords.split('|||||')
}

export const coordsHMSToDecimal = (orig_lat, orig_long) => {
  if (!orig_lat || !orig_long) {
    return [-1, -1]
  }
  // console.log(orig_lat, orig_long)

  let lat = -1,
    lon = -1
  try {
    const value = parseDMS(orig_lat + orig_long)
    lat = value.lat
    lon = value.lon
  } catch (e) {
    Sentry.captureException(e)
    return [-1, -1]
  }
  return [lat, lon]
}

export const formatLatLong = (lat, long, isPublic) => {
  // coordinates are in the WSG84 format (by default from the geoloc api)
  // TODO : seamen use Hour / Minutes / Seconds to display the geoloc while
  // developpers use decimal.
  // Here is a conversion code: https://stackoverflow.com/questions/1140189/converting-latitude-and-longitude-to-decimal-values
  // console.log(lat, long)
  coordsHMSToDecimal(...coordsDecimalToHMS(lat, long))

  if (isPublic) {
    return ''
  }

  if (lat && long) {
    const [hmsLat, hmsLong] = coordsDecimalToHMS(lat, long)
    return `${hmsLat}, ${hmsLong}`
  }
}

export const onlyInstalledTags = tags => {
  tags = tags || []
  return tags.filter(t => t.status === 'installed')
}

export const onlyAttributedTags = tags => {
  tags = tags || []
  return tags.filter(e => e.status === 'attributed')
}

export const onlyRecapturedTags = tags => {
  tags = tags || []
  return tags.filter(t => t.events && t.events.length > 1)
}

export const onlyLostTags = tags => {
  tags = tags || []
  return tags.filter(t => t.status === 'lost')
}

const buildStatsForMember = (member, observations, tags) => {
  return {
    remain: onlyAttributedTags(tags).length,
    lost: onlyLostTags(tags).length,
    installed: onlyInstalledTags(tags).length,
    observations: observations.length
  }
}

export const addFieldsToMember = (member, allObservations, allTags) => {
  const memberObservations = allObservations.filter(
    o => o.member_id === member.member_id
  )
  let memberTags = allTags.filter(t => t.member_id === member.member_id)
  memberTags.sort((t1, t2) => t1.id - t2.id)
  const statistics = buildStatsForMember(member, memberObservations, memberTags)

  member.statistics = statistics
  member.observations = memberObservations
  member.tags = memberTags
  return member
}

const eventIsEmpty = event => {
  const { date } = event
  return date === ''
}

export const getLastEventIndex = events => {
  let lastNonEmptyEvent = 0
  for (let eventIndex = 0; eventIndex < events.length; eventIndex++) {
    lastNonEmptyEvent = !eventIsEmpty(events[eventIndex])
      ? eventIndex
      : lastNonEmptyEvent
  }
  return lastNonEmptyEvent
}

export const onlyEventsWithData = events => {
  return events.filter(e => !eventIsEmpty(e))
}
