import React, { Component } from 'react'
import moment from 'moment'
import { Form, Space, Row, Col, Button, message } from 'antd'
import Title from 'antd/lib/typography/Title'
import TagId from './TagId'
import Species from './Species'
import UploadImage from '../shared/UploadEventImage'
import Measurements from './Measurements'
import EventInformation from '../shared/EventInformation'

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo)
  message.error("Présence d'erreurs dans le formulaire")
}

class NewTagForm extends Component {
  onFinish = values => {
    let newTag = this.props.tags.filter(t => t.id === values.id)[0]

    const date = values.datetime.format('YYYY-MM-DD')
    const time = values.datetime.format('HH:mm')

    newTag.species = values.species
    newTag.status = 'installed'
    newTag.events[0].circumference = values.circumference || ''
    newTag.events[0].date = date
    newTag.events[0].time = time
    newTag.events[0].latitude = values.latitude
    newTag.events[0].longitude = values.longitude
    newTag.events[0].length = values.length || ''
    newTag.events[0].weight = values.weight || ''
    newTag.events[0].picture = this.state.picture_url || ''

    console.log('nextTag', newTag)
    this.props.update(newTag)
  }

  setImagePath = path => {
    this.setState({ picture_url: path })
  }

  formRef = React.createRef()

  setFieldValues = values => {
    if (this.formRef && this.formRef.current) {
      const currentValues = this.formRef.current.getFieldsValue()
      this.formRef.current.setFieldsValue({ ...currentValues, ...values })
    }
  }

  selectedTagId =
    // eslint-disable-next-line no-restricted-globals
    history && // eslint-disable-next-line no-restricted-globals
    history.state && // eslint-disable-next-line no-restricted-globals
    history.state.state && // eslint-disable-next-line no-restricted-globals
    history.state.state.tagId
      ? // eslint-disable-next-line no-restricted-globals
        history.state.state.tagId
      : null

  render () {
    const { tags, species } = this.props

    return (
      <Form
        ref={this.formRef}
        name='basic'
        onFinish={this.onFinish}
        onFinishFailed={onFinishFailed}
        className='fullpage'
        initialValues={{
          datetime: moment(),
          id: this.selectedTagId
        }}
        style={{ width: '100%' }}
      >
        <Space direction='vertical' size='large' style={{ width: '100%' }}>
          <div className='flex-container'>
            <TagId
              selectedTagId={this.selectedTagId}
              tags={tags}
              tagIds={tags.map(t => t.id)}
              onSelect={v => this.setState({ selectedTagId: v })}
            ></TagId>
          </div>
          <Space direction='vertical' size='small' style={{ width: '100%' }}>
            <Title level={2}>Spécimen</Title>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Species
                  species={species}
                  onSelect={v => this.setState({ selectedSpecies: v })}
                ></Species>
              </Col>
              <Col xs={24}>
                <Measurements></Measurements>
              </Col>

              <Title level={3}>Media</Title>
              <Col xs={24}>
                <UploadImage
                  domain='tags'
                  setImagePath={this.setImagePath}
                  legend='Image du specimen'
                ></UploadImage>
              </Col>

              <EventInformation
                setFieldValues={this.setFieldValues}
                isCapture={true}
              ></EventInformation>
            </Row>
          </Space>
          <Button type='primary' htmlType='submit'>
            Ok
          </Button>
        </Space>
      </Form>
    )
  }
}

export default NewTagForm
