import React, { Component } from 'react'
import { Space, Input, Select } from 'antd'
import Title from 'antd/lib/typography/Title'
import { FaPhone } from 'react-icons/fa'

const { Option } = Select

class FormProfileContact extends Component {
  updateField = (name, e) => {
    console.log(name, e.target.value)
    let newMember = this.props.member
    if (this.props.member[name] === e.target.value) {
      console.log("don't update")
      return
    }
    newMember[name] = e.target.value
    this.props.update(newMember)
  }

  render () {
    const { member } = this.props
    return (
      <div>
        <Title level={2}>Contact</Title>
        <div className=''>
          <Space direction='vertical'>
            <div>Les modifications sont enregistrées automatiquement.</div>
            <div>
              <Input.Group compact>
                <Select
                  style={{ width: '30%' }}
                  defaultValue={member.country}
                  onChange={e => this.updateField('country', e)}
                >
                  <Option value='Monaco'>Monaco</Option>
                  <Option value='France'>France</Option>
                  <Option value='Italie'>Italie</Option>
                </Select>
                <Input
                  style={{ width: '70%' }}
                  defaultValue={member.address}
                  placeholder='adresse'
                  onBlur={e => this.updateField('address', e)}
                />
              </Input.Group>
              <Input.Group>
                <Input
                  style={{ width: '30%' }}
                  defaultValue={member.postal_code}
                  placeholder='code postal'
                  onBlur={e => this.updateField('postal_code', e)}
                ></Input>
                <Input
                  style={{ width: '70%' }}
                  defaultValue={member.city}
                  placeholder='ville'
                  onBlur={e => this.updateField('city', e)}
                />
              </Input.Group>
            </div>

            <div>
              <Input
                suffix={<FaPhone className='icon'></FaPhone>}
                style={{
                  width: '10em'
                }}
                placeholder='Téléphone'
                onBlur={e => this.updateField('phone_number', e)}
                defaultValue={member.phone_number}
              />
            </div>
          </Space>
        </div>
      </div>
    )
  }
}

export default FormProfileContact
