import React, { Component } from 'react'
import { Col, InputNumber, Radio, Input, Switch, Form } from 'antd'
import Title from 'antd/lib/typography/Title'

const { TextArea } = Input

export default class ObservationInformation extends Component {
  state = { young: 'na' }
  onYoungPresenceChange = e => this.setState({ young: e.target.value })

  render () {
    return (
      <>
        <Col xs={24}>
          <Title level={2}>Informations sur l'animal</Title>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name='alive'>
            <Switch
              checkedChildren='vivant'
              unCheckedChildren='decedé'
              defaultChecked
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} style={{ zIndex: 0 }}>
          Présence de jeunes :
          <Form.Item
            name='youngs'
            rules={[
              {
                required: true,
                message:
                  "Indiquez si des jeunes étaient présents s'il vous plaît"
              }
            ]}
          >
            <Radio.Group
              onChange={this.onYoungPresenceChange}
              buttonStyle='solid'
            >
              <Radio.Button value='oui'>oui</Radio.Button>
              <Radio.Button value='non'>non</Radio.Button>
              <Radio.Button value='NA'>pas sûr</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name='group_size'>
            <InputNumber
              precision={0}
              type='number'
              style={{ width: '100%' }}
              placeholder="Nombre d'individus"
            />
          </Form.Item>
        </Col>

        {this.props.category === 'Tortues Marines' ? (
          <Form.Item name='shell_size'>
            <Col xs={24}>
              <InputNumber
                precision={0}
                type='number'
                style={{ width: '100%' }}
                placeholder='Estimation taille carapace (cm)'
              />
            </Col>
          </Form.Item>
        ) : null}

        <Col xs={24}>
          <Title level={3}>Description libre</Title>
          <Form.Item name='free_description'>
            <TextArea rows={4} />
          </Form.Item>
        </Col>
      </>
    )
  }
}
