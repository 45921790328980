import React, { Component } from 'react'
import { PageHeader, Button } from 'antd'
import { Redirect, Link } from 'react-router-dom'

class Navigation extends Component {
  state = { redirect: '' }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}></Redirect>
    }
    return (
      <PageHeader
        style={{ zIndex: 999 }}
        className='site-page-header-responsive'
        onBack={() => this.setState({ redirect: '/member' })}
        backIcon={this.props.backIcon}
        title={this.props.title}
        extra={[
          <Link key='1-1' to='/new-tag'>
            <Button key='1-2' type='primary' id='new-tag'>
              Nouveau Marquage
            </Button>
          </Link>,
          <Link key='2-1' to='/new-observation'>
            <Button key='2-2' id='new-observation'>
              Nouvelle Observation
            </Button>
          </Link>
        ]}
      ></PageHeader>
    )
  }
}

export default Navigation
