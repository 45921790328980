import React, { Component } from 'react'
import { Space, Row } from 'antd'
import Title from 'antd/lib/typography/Title'

import RecaptureCard from '../shared/RecaptureCard'
import { onlyInstalledTags } from '../../utils'
import EventsMap from '../shared/EventsMap'

class TagsRecaptureHistory extends Component {
  installedTags = onlyInstalledTags(this.props.tags)

  render () {
    return (
      <section>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Title level={2}>Historique des captures / recaptures</Title>
          <EventsMap tags={this.installedTags}></EventsMap>

          <Row gutter={[16, 16]}>
            {this.installedTags.map(t => {
              return <RecaptureCard {...t}></RecaptureCard>
            })}
          </Row>
        </Space>
      </section>
    )
  }
}

export default TagsRecaptureHistory
