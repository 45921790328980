import React from 'react'
import { FaUserEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default function Greatings ({ member }) {
  return (
    <div>
      Bonjour {member.surname}
      <Link to='/profile'>
        <span className='icon' style={{ marginLeft: '1em' }}>
          <FaUserEdit></FaUserEdit>
        </span>
      </Link>
    </div>
  )
}
