import React, { Component, Fragment } from 'react'
import { Card, Col, Skeleton, message, Space } from 'antd'
import {
  requireImage,
  getLastEventIndex,
  onlyEventsWithData,
  humanizeSpecies
} from '../../utils'
import { FaTag } from 'react-icons/fa'
import CardDateTime from './CardDatePos'
import CardSize from './CardSize'
import UploadImage from '../shared/UploadEventImage'
import { updateTag } from '../../base'

const ImageCover = ({ picture }) => (
  <div className='parent'>
    <div
      className='child'
      style={{
        backgroundImage: `url(${picture}), url(${requireImage(picture)})`
      }}
    ></div>
  </div>
)

const AddImageCover = ({ update }) => (
  <div style={{ height: '250px' }} className='flex-container'>
    <UploadImage
      domain='captures'
      legend='Ajouter une image'
      setImagePath={update}
    />
  </div>
)

class CaptureCard extends Component {
  // https://css-tricks.com/zooming-background-images/

  state = { picture: '' }

  updateCapture = path => {
    const thisBackup = this

    let tag = this.props.tag
    const lastNonEmptyEventIndex = getLastEventIndex(this.props.events)
    tag.events[lastNonEmptyEventIndex].picture = path || ''

    updateTag(
      tag,
      () => {
        message.success('Image ajoutée à la capture')
        thisBackup.setState({ picture: path })
      },

      error => message.error(error.message)
    )
  }

  render () {
    const {
      species,
      events,
      loading,
      member_id,
      member,
      isPublic // display GPS or not
    } = this.props

    let date = null,
      time = null,
      latitude = null,
      longitude = null,
      length = null,
      circumference = null,
      weight = null,
      picture = null

    if (this.props.public === 'no') {
      return null
    }

    if (!loading) {
      const dataEvents = onlyEventsWithData(events)
      if (dataEvents.length === 0) {
        return null
      }

      const lastNonEmptyEventIndex = getLastEventIndex(events)
      const lastEvent = events[lastNonEmptyEventIndex]
      if (lastEvent) {
        ;({
          date,
          time,
          latitude,
          longitude,
          length,
          picture,
          circumference,
          weight
        } = lastEvent)
        if (this.state.picture !== picture) {
          this.setState({ picture })
        }
      }
    }

    const humanSpecies = humanizeSpecies(this.props.allSpecies, species)

    return (
      <Col xs={24} md={12} lg={8} xl={6}>
        <Skeleton loading={loading} active>
          <Card
            style={{}}
            title={
              <Fragment>
                <h3 className='text-center'>{species}</h3>
                {humanSpecies ? (
                  <p className='text-center text-muted'> {humanSpecies} </p>
                ) : null}
                <p className='text-center'>
                  <Space>
                    <FaTag className='icon' />
                    par {member_id}
                  </Space>
                </p>
              </Fragment>
            }
            cover={
              member &&
              member.member_id === member_id &&
              this.state.picture === '' ? (
                <AddImageCover update={this.updateCapture}></AddImageCover>
              ) : (
                <ImageCover picture={this.state.picture}></ImageCover>
              )
            }
          >
            <div className='text-center'>
              <CardDateTime
                isPublic={isPublic}
                longitude={longitude}
                latitude={latitude}
                date={date}
                time={time}
              ></CardDateTime>
              <CardSize
                length={length}
                circumference={circumference}
                weight={weight}
              ></CardSize>
            </div>
          </Card>
        </Skeleton>
      </Col>
    )
  }
}

export default CaptureCard
