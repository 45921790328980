import React from 'react'
import { Select, Form } from 'antd'

const { Option } = Select

export default function Species (props) {
  const { species, onSelect } = props
  return (
    <Form.Item
      name='species'
      rules={[
        {
          required: true,
          message: "Renseignez l'espèce' s'il vous plaît"
        }
      ]}
    >
      <Select
        showSearch
        size='large'
        style={{ width: '100%' }}
        placeholder='Espèce marquée'
        optionFilterProp='children'
        onSelect={onSelect}
      >
        {species
          .filter(specie => specie.allow_tagging === 'yes')
          .map((specie, index) => {
            return (
              <Option key={index} value={specie.scientific_name}>
                {specie.vernacular_name} ({specie.scientific_name})
              </Option>
            )
          })}
      </Select>
    </Form.Item>
  )
}
