import React, { Component } from 'react'
import Title from 'antd/lib/typography/Title'
import { Input, Space, Form, Button } from 'antd'

const validateMessages = {
  required: 'Le champ est requis !',
  types: {
    email: "Le champ n'est pas un email valide!"
  }
}

class FormProfileEmail extends Component {
  changeEmail = value => {
    // we need to only change the email in the firebase member,
    // the auth modification is handled by a firebase server function
    console.log(value)
    let newMember = this.props.member
    newMember.email = value.email
    this.props.update(newMember)
  }

  render () {
    return (
      <div>
        <Title level={2}>Email</Title>
        <div className='flex-container'>
          <Space direction='vertical'>
            <div>
              Attention l'email est utilisé comme login, vous devrez utiliser
              celui renseigné pour votre prochaine connexion.
            </div>

            <Form
              layout='inline'
              onFinish={this.changeEmail}
              validateMessages={validateMessages}
              initialValues={{ email: this.props.member.email }}
            >
              <Form.Item
                style={{ width: '80%' }}
                validateMessages={validateMessages}
                validateTrigger='onBlur'
                rules={[{ required: true }]}
                name='email'
              >
                <Input
                  placeholder='Nouvel Email'
                  enterButton='Ok'
                  size='large'
                  type='email'
                  required={true}
                />
              </Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                size='large'
                style={{ width: '10%', padding: '1%' }}
              >
                Ok
              </Button>
            </Form>
          </Space>
        </div>
      </div>
    )
  }
}

export default FormProfileEmail
