import React, { Component } from 'react'
import { Layout, Menu, Space } from 'antd'
import { Link } from 'react-router-dom'
import LoginButton from './LoginButton'
import EmergencyNumber from './EmergencyNumber'

const { Header, Content, Footer } = Layout

class BasePage extends Component {
  // https://ant.design/components/layout/

  render () {
    const MyNavigation = this.props.myNavigation || ''
    return (
      <Layout className='layout' style={{ minHeight: '100vh' }}>
        <Header
          style={{
            position: 'fixed',
            zIndex: 9999,
            width: '100%'
          }}
        >
          <Link to='/'>
            <img
              className='logo'
              style={{ float: 'left' }}
              src={require('../../img/logo.jpg')}
              alt=''
            />
          </Link>
          <Menu style={{ float: 'right' }} theme='dark' mode='horizontal'>
            <Space>
              <EmergencyNumber></EmergencyNumber>
              <LoginButton user={this.props.user} />
            </Space>
          </Menu>
          <Menu
            style={{
              float: 'left',
              clear: 'left',
              width: '100vw',
              position: 'relative',
              left: '-50px'
            }}
          >
            {MyNavigation ? MyNavigation : ''}
          </Menu>
        </Header>

        <Content
          style={{ padding: '2em' }}
          className={
            MyNavigation ? 'main-has-navigation' : 'main-has-no-navigation'
          }
        >
          {this.props.children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>MFConsulting</Footer>
      </Layout>
    )
  }
}

export default BasePage
