import React, { Component } from 'react'
import { Col, InputNumber, Form, Row } from 'antd'

export default class Measurements extends Component {
  state = {
    weightEntered: false,
    lengthEntered: false,
    circEntered: false
  }

  render () {
    const required = !(
      this.state.weightEntered ||
      this.state.weightEntered ||
      this.state.lengthEntered
    )
    return (
      <Row gutter={[8, 8]}>
        <Col xs={24} md={8}>
          <Form.Item
            name='length'
            rules={[
              {
                required: required,
                message: "Renseignez la longueur s'il vous plaît"
              }
            ]}
          >
            <InputNumber
              precision={0}
              size='large'
              onChange={value => {
                this.setState({ lengthEntered: Boolean(value) })
              }}
              placeholder='Longueur (en cm)'
              style={{ width: '100%' }}
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name='weight'
            rules={[
              {
                required: required,
                message: "Renseignez le poids s'il vous plaît"
              }
            ]}
          >
            <InputNumber
              precision={0}
              size='large'
              placeholder='Poids estimé (en kg)'
              style={{ width: '100%' }}
              onChange={value =>
                this.setState({ weightEntered: Boolean(value) })
              }
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name='circumference'
            rules={[
              {
                required: required,
                message: "Renseignez la circonférence s'il vous plaît"
              }
            ]}
          >
            <InputNumber
              precision={0}
              size='large'
              onChange={value => this.setState({ circEntered: Boolean(value) })}
              placeholder='Circonférence (en cm)'
              style={{ width: '100%' }}
            ></InputNumber>
          </Form.Item>
        </Col>
      </Row>
    )
  }
}
