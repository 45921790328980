import React, { Component } from 'react'
import CaptureCard from '../shared/CaptureCard'
import { Row, Button, Col } from 'antd'
import Title from 'antd/lib/typography/Title'
import { onlyInstalledTags } from '../../utils'

const lastCaptures = (allSpecies, captures, loading) => {
  return captures.map((el, index) => {
    return (
      <CaptureCard
        allSpecies={allSpecies}
        loading={loading}
        key={index}
        isPublic={true}
        {...el}
      />
    )
  })
}

class PublicLastCaptures extends Component {
  state = { nb_to_display: 8 }

  render () {
    const { allSpecies, loading } = this.props
    let { captures } = this.props

    captures = loading ? [1, 2, 3] : onlyInstalledTags(captures)
    if (!loading && captures.length === 0) {
      return null
    }

    const newCaptures = captures.slice(0, this.state.nb_to_display)

    return (
      <div>
        <Title level={2}>Dernières captures</Title>
        <p>Les informations détaillées sont disponibles sur demande.</p>
        <div className='site-card-wrapper'>
          <Row gutter={[16, 16]}>
            {lastCaptures(allSpecies, newCaptures, loading)}
            <Col xs={24}>
              <div className='text-center'>
                <Button
                  onClick={e => {
                    this.setState({
                      nb_to_display: this.state.nb_to_display + 8
                    })
                  }}
                >
                  Afficher plus de captures
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default PublicLastCaptures
