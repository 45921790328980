import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import Navigation from '../shared/Navigation'
import TagsManagement from './TagsManagement'
import TagsRecaptureHistory from './TagsRecaptureHistory'
import { Space } from 'antd'
import { onlyAttributedTags } from '../../utils'

class TagsPage extends Component {
  navigation = () => {
    return <Navigation title={'Vos captures'}></Navigation>
  }

  render () {
    return (
      <BasePage myNavigation={this.navigation()} user={this.props.user}>
        <Space
          direction='vertical'
          size='large'
          className='fullpage'
          style={{ width: '100%' }}
        >
          <TagsManagement
            update={this.props.update}
            tags={onlyAttributedTags(this.props.member.tags)}
          ></TagsManagement>
          <TagsRecaptureHistory
            allSpecies={this.props.species}
            tags={this.props.member.tags}
          ></TagsRecaptureHistory>
        </Space>
      </BasePage>
    )
  }
}

export default TagsPage
