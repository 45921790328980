import React, { Component } from 'react'
import Map from 'pigeon-maps'
import Marker from 'pigeon-marker'
import Overlay from 'pigeon-overlay'
import 'react-virtualized/styles.css' // only needs to be imported once
import { onlyEventsWithData } from '../../utils'
const createOverlay = markerData => {
  return markerData.map((m, i) => {
    return (
      <Overlay
        anchor={m.latlong}
        payload={1}
        key={i}
        onClick={({ event, anchor, payload }) => {
          console.log(event, anchor, payload)
        }}
        offset={[60, 39]}
        style={{ boxShadow: '3px 3px 5px rgba(0,0,0,0.5)' }}
      >
        <div style={{ background: 'white' }} className='flex-container'>
          <img
            src={m.picture}
            width={120}
            alt=''
            style={{ display: 'block' }}
          />
          <div
            style={{ width: '110px', overflow: 'hidden', textAlign: 'center' }}
          >
            {m.species}
          </div>
          <div>
            {m.date} : {m.time}
          </div>
        </div>
      </Overlay>
    )
  })
}

const createMarkers = markerData => {
  markerData = markerData || []
  return markerData.map((m, i) => {
    if (m[0] && m[1])
      return <Marker anchor={[m[0], m[1]]} payload={1} key={i}></Marker>
    else return null
  })
}

export const map = (
  latlongcenter,
  zoom,
  overlays,
  width,
  height,
  onClick,
  markers
) => {
  return (
    <Map
      onClick={onClick}
      defaultCenter={latlongcenter}
      defaultZoom={zoom || 12}
      width={width}
      height={400}
    >
      {createOverlay(overlays)}
      {createMarkers(markers)}
    </Map>
  )
}

class EventMaps extends Component {
  render () {
    const latLongCenter = [43.5322, 7.0734]
    const zoom = 7.7
    const tags = this.props.tags
    const observations = this.props.observations

    let markers = []
    if (tags !== undefined) {
      for (let tagIndex = 0; tagIndex < tags.length; tagIndex++) {
        const tag = tags[tagIndex]
        const events = onlyEventsWithData(tag.events)
        for (let eventIndex in events) {
          const event = events[eventIndex]
          markers.push({
            species: tag.species,
            date: event.date,
            time: event.time,
            recaptureNb: tagIndex + 1,
            picture: event.picture,
            latlong: [parseFloat(event.latitude), parseFloat(event.longitude)]
          })
        }
      }
    }

    if (observations !== undefined) {
      for (let observationIndex in observations) {
        const observation = observations[observationIndex]
        markers.push({
          species: observation.species,
          date: observation.date,
          time: observation.time,
          picture: observation.picture,
          latlong: [
            parseFloat(observation.latitude),
            parseFloat(observation.longitude)
          ]
        })
      }
    }

    // const markers = [{ latlong: latLongCenter }, { latlong: [43.1322, 7.8734] }]
    return <div>{map(latLongCenter, zoom, markers, this.props.onClick)}</div>
  }
}

export default EventMaps
