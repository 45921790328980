import React, { Component } from 'react'
import { Button, message } from 'antd'
import { Redirect } from 'react-router-dom'
import { firebaseApp } from '../../base'

class LoginButton extends Component {
  state = {
    redirect: ''
  }

  handleClick = e => {
    const thisClass = this //  here is how you assign this to variable
    if (this.props.user) {
      firebaseApp
        .auth()
        .signOut()
        .then(function () {
          thisClass.setState({ redirect: '/' })
          message.success('Utilisateur déconnecté')
        })
        .catch(function (error) {
          message.error(JSON.stringify(error))
        })
    } else {
      this.setState({ redirect: '/member' })
    }
  }

  render () {
    const { className, children, user, style } = this.props
    if (this.state.redirect !== '') {
      return <Redirect push to={this.state.redirect} />
    }
    return (
      <Button
        onClick={e => {
          this.handleClick()
        }}
        className={className}
        style={style}
      >
        {children
          ? children
          : user && user.uid
          ? 'Déconnexion'
          : 'Accès Membre'}
      </Button>
    )
  }
}

export default LoginButton
