import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import { Space, Select } from 'antd'
import Navigation from '../shared/Navigation'
import { Redirect } from 'react-router-dom'
import Title from 'antd/lib/typography/Title'
import {
  humanizeSpecies,
  onlyEventsWithData,
  onlyInstalledTags
} from '../../utils'

import ReactMapboxGl, { Marker, Cluster, Popup } from 'react-mapbox-gl'
import config from './config'
import { FaTag, FaBinoculars } from 'react-icons/fa'

const { Option } = Select

const Mapbox = ReactMapboxGl({
  accessToken: config.token
})

class EventMapPage extends Component {
  navigation = () => {
    return <Navigation title='Carte des événements' />
  }

  state = {
    lng: 3.0,
    lat: 42.84,
    zoom: 4.84,
    selectedTagSpecies: [],
    selectedObservationSpecies: [],
    markers: [],
    popup: undefined
  }

  clusterClick = (coordinates, total, getLeaves) => {
    console.log('cluster click')
  }

  clusterMarker = (coordinates, pointCount, getLeaves) => (
    <Marker
      className='cluster-marker'
      key={coordinates.toString()}
      coordinates={coordinates}
      onClick={this.clusterClick.bind(this, coordinates, pointCount, getLeaves)}
    >
      <div>{pointCount}</div>
    </Marker>
  )

  getInstalledTagsBySpecies () {
    const installedTags = onlyInstalledTags(this.props.tags)
    const tagsGroupBySpecies = installedTags.reduce((r, a) => {
      r[a.species] = [...(r[a.species] || []), a]
      return r
    }, {})
    return tagsGroupBySpecies
  }

  getObservationsBySpecies () {
    return this.props.observations.reduce((r, a) => {
      r[a.species] = [...(r[a.species] || []), a]
      return r
    }, {})
  }

  getSpeciesChoice (tagsGroupBySpecies) {
    const children = []
    for (const speciesName in tagsGroupBySpecies) {
      children.push(
        <Option key={speciesName}>
          {`${humanizeSpecies(
            this.props.species,
            speciesName
          )} (${speciesName})`}
        </Option>
      )
    }
    return children
  }

  updateTagsToDisplay () {
    const toDisplay = []
    for (const species of this.state.selectedTagSpecies) {
      for (const element of this.tagsGroupBySpecies[species]) {
        for (const event of onlyEventsWithData(element.events)) {
          const res = {
            context: [`tag id: ${element.id}`, `status: ${element.status}`],
            species: element.species,
            lat: event.latitude,
            long: event.longitude,
            date: `${event.date} ${event.time}`,
            origin: 'capture'
          }
          toDisplay.push(res)
        }
      }
    }
    console.log('toDisplay', toDisplay)
    return toDisplay
  }

  updateObservationsToDisplay () {
    const toDisplay = []
    for (const species of this.state.selectedObservationSpecies) {
      for (const element of this.observationsGroupBySpecies[species]) {
        const context = []
        if (element.group_size > 0) {
          context.push(`taille du groupe : ${element.group_size}`)
        }
        if (element.shell_size > 0) {
          context.push(`taille de la carapace : ${element.shell_size}`)
        }
        if (element.free_description > 0) {
          context.push(`description : ${element.free_description}`)
        }
        context.push(`id : ${element.id}`)

        toDisplay.push({
          context: context,
          species: element.species,
          lat: element.latitude,
          long: element.longitude,
          date: `${element.date} ${element.time}`,
          origin: 'observation'
        })
      }
    }
    return toDisplay
  }

  updateElementToDisplay () {
    const toDisplay = this.updateTagsToDisplay()
    toDisplay.push(...this.updateObservationsToDisplay())

    this.markers = toDisplay
  }

  handleOnMove = (map, event) => {
    this.setState({
      lng: map.getCenter().lng,
      lat: map.getCenter().lat,
      zoom: map.getZoom()
    })
    if (this.state.popup) {
      this.setState({ popup: undefined })
    }
  }

  render () {
    if (this.props.redirect) {
      const redirect = this.props.redirect
      this.props.redirectDone()
      return <Redirect to={redirect} />
    }

    this.tagsGroupBySpecies = this.getInstalledTagsBySpecies()
    this.observationsGroupBySpecies = this.getObservationsBySpecies()
    const taggedSpecies = this.getSpeciesChoice(this.tagsGroupBySpecies)
    const observedSpecies = this.getSpeciesChoice(
      this.observationsGroupBySpecies
    )

    const { popup } = this.state
    this.updateElementToDisplay()

    return (
      <BasePage>
        <Title level={1}>Carte des événements</Title>

        <Space
          direction='vertical'
          size='large'
          className='fullpage'
          style={{ width: '100%' }}
        >
          <div>
            <div>
              Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom:{' '}
              {this.state.zoom}
            </div>
          </div>

          <Mapbox
            style={config.styles.outdoor}
            zoom={[this.state.zoom]}
            center={[this.state.lng, this.state.lat]}
            onMove={this.handleOnMove}
          >
            <Cluster ClusterMarkerFactory={this.clusterMarker}>
              {this.markers.map((marker, key) => (
                <Marker
                  key={key}
                  coordinates={[marker.long, marker.lat]}
                  data-feature={marker}
                  className='map-marker'
                  onClick={() => this.setState({ popup: marker })}
                >
                  <div title={marker.species} className='text-center'>
                    {marker.origin === 'capture' ? (
                      <FaTag className='icon' />
                    ) : (
                      <FaBinoculars className='icon' />
                    )}
                    {marker.species}
                  </div>
                </Marker>
              ))}
            </Cluster>
            {popup && (
              <Popup
                key={popup.id}
                coordinates={[popup.long, popup.lat]}
                offsetTop={-30}
              >
                <h2>
                  {`${humanizeSpecies(this.props.species, popup.species)} (${
                    popup.species
                  })`}
                </h2>

                <h3>Date : {popup.date}</h3>
                <h3>
                  Position : {popup.long}, {popup.lat}
                </h3>

                <ul style={{ paddingLeft: '10px' }}>
                  {popup.context.map((context, index) => (
                    <li key={index}>{context}</li>
                  ))}
                </ul>
              </Popup>
            )}
          </Mapbox>

          <Space
            direction='vertical'
            size='large'
            className='fullpage'
            style={{ width: '100%' }}
          >
            <div>
              <Title level={2}>Espèces tagguées</Title>
              <Select
                mode='multiple'
                allowClear
                style={{ width: '100%' }}
                placeholder='Sélectionner une ou plusieurs espèces capturées'
                onChange={value => {
                  this.setState({ selectedTagSpecies: value })
                }}
              >
                {taggedSpecies}
              </Select>
            </div>

            <div className=''>
              <Title level={2}>Espèces observées</Title>
              <Select
                mode='multiple'
                allowClear
                style={{ width: '100%' }}
                placeholder='Sélectionner une ou plusieurs espèces observées'
                onChange={value => {
                  this.setState({ selectedObservationSpecies: value })
                }}
              >
                {observedSpecies}
              </Select>
            </div>
          </Space>
        </Space>
      </BasePage>
    )
  }
}

export default EventMapPage
