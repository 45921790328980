import React, { Component } from 'react'
import { Row, Col, Modal } from 'antd'
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const showDeleteConfirm = ({ tag, tagId, update }) => {
  Modal.confirm({
    title: `Marquer le tag ${tagId} comme perdu ?`,
    icon: <ExclamationCircleOutlined />,
    content: "Vous n'y aurai plus accès dans votre inventaire.",
    okText: 'Oui',
    okType: 'danger',
    cancelText: 'Non',
    onOk () {
      let newTag = tag
      newTag.status = 'lost'
      update(newTag)
    },
    onCancel () {}
  })
}

const DeleteElement = props => {
  return (
    <button
      onClick={e => showDeleteConfirm(props)}
      key='delete'
      className='danger'
    >
      <DeleteOutlined></DeleteOutlined>
    </button>
  )
}

const UseElement = props => {
  return (
    <Link to={{ pathname: '/new-tag', state: { tagId: props.tagId } }}>
      {props.children}
    </Link>
  )
}

class TagsList extends Component {
  render () {
    return (
      <Row gutter={[16, 16]} style={{ width: '90%' }}>
        {this.props.tags.map(tag => {
          const tagId = tag.id
          return (
            <Col xs={24} sm={12} md={8} xl={6}>
              <div
                style={{
                  width: '100%',
                  border: 'solid #fafafa 1px',
                  backgroundColor: '#fafafa',
                  marginLeft: '10px',
                  marginRight: '10px',
                  alignItems: 'initial',
                  padding: '1em',
                  justifyContent: 'initial',
                  flexDirection: 'initial'
                }}
                className='flex-container'
              >
                <span>
                  <UseElement tagId={tagId}>Tag numéro : {tagId}</UseElement>
                </span>
                <span className='fill-remaining-space'></span>
                <span>
                  <DeleteElement
                    tag={tag}
                    tagId={tagId}
                    update={this.props.update}
                  ></DeleteElement>
                </span>
              </div>
            </Col>
          )
        })}
      </Row>
    )
  }
}

export default TagsList
