import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import { Form, Input, Button, Alert, Spin, Space } from 'antd'
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
import { firebaseApp } from '../../base'
import Title from 'antd/lib/typography/Title'
import { Redirect } from 'react-router-dom'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class LoginPage extends Component {
  state = {
    authError: '',
    redirect: false,
    loading: false
  }

  onFinish = values => {
    this.setState({ loading: true })
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(auth => {
        console.log('auth success', auth)
        this.setState({ authError: '', redirect: true, loading: false })
      })
      .catch(error => {
        console.error('error', error)
        this.setState({ authError: error, redirect: false, loading: false })
      })
  }

  render () {
    if (this.state.redirect || this.props.user.uid) {
      return <Redirect to='/member'></Redirect>
    }
    return (
      <BasePage>
        <div className='flex-container' style={{ height: '40em' }}>
          <div>
            {this.state.authError !== '' && (
              <Alert
                message='Error'
                description={this.state.authError.message}
                type='error'
                showIcon
                style={{ marginBottom: '2em' }}
              />
            )}
          </div>
          <div>
            <Title>Connexion</Title>

            <div>
              <p>
                Votre compte a été créé lors de votre inscription à la
                fédération.
              </p>
              <p>L'email à utiliser est celui que vous nous avez communiqué.</p>
            </div>
          </div>
          <Form
            name='normal_login'
            className='login-form'
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              name='email'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: "Entrez votre email s'il vous plaît",
                  type: 'email'
                }
              ]}
            >
              <Input
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Email'
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: "Entrez votre mot de passe s'il vous plaît"
                }
              ]}
            >
              <Input
                prefix={<LockOutlined className='site-form-item-icon' />}
                type='password'
                placeholder='Password'
              />
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
                style={{ width: '100%' }}
              >
                <Space>
                  <span>
                    {this.state.loading ? 'Connexion en cours' : 'Se connecter'}
                  </span>
                  {this.state.loading ? (
                    <Spin
                      className='icon'
                      style={{ color: '#fafafa' }}
                      indicator={antIcon}
                    />
                  ) : null}
                </Space>
              </Button>
            </Form.Item>

            <Button
              type='secondary'
              className='login-form-button'
              style={{ width: '100%', marginTop: '20px' }}
              href={'/forgot-password'}
            >
              Mot de passe oublié
            </Button>
          </Form>
        </div>
      </BasePage>
    )
  }
}

export default LoginPage
