import Rebase from 're-base'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import { message } from 'antd'
import { getLastEventIndex } from './utils'

// const firebaseConfig = {
//   apiKey: 'AIzaSyDzbx4rcH7TzyD3UfFk_qV5ag8gnppd1as',
//   authDomain: 'test-fpmm.firebaseapp.com',
//   databaseURL: 'https://test-fpmm.firebaseio.com',
//   projectId: 'test-fpmm',
//   storageBucket: 'test-fpmm.appspot.com',
//   messagingSenderId: '621474126311',
//   appId: '1:621474126311:web:75a078b9549e13d2a74542'
// }

const firebaseConfig = {
  apiKey: 'AIzaSyC-XcVxCjM4Bwl-VnvWNdb-QLWSN7Md240',
  authDomain: 'science-participative.firebaseapp.com',
  databaseURL: 'https://science-participative.firebaseio.com',
  projectId: 'science-participative',
  storageBucket: 'science-participative.appspot.com',
  messagingSenderId: '779711121374',
  appId: '1:779711121374:web:e6f7a49bea52b5f3f87f4f',
  measurementId: 'G-6NV1BJVMSW'
}

var firebaseApp = firebase.initializeApp(firebaseConfig)
var storage = firebase.storage()

export const { TaskState } = firebase.storage

var firestore = firebaseApp.firestore()
var settings = {}
firestore.settings(settings)
var base = Rebase.createClass(firestore)

function allowOfflineMode (onError) {
  firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
      onError(err)
    })
}

allowOfflineMode(err => {
  message.error(err.message)
})

function getSpecies (onSuccess, onError) {
  firebaseApp
    .firestore()
    .collection('species')
    .get()
    .then(snapshot => {
      let species = []
      snapshot.forEach(doc => {
        species.push(doc.data())
      })
      onSuccess(species)
    })
    .catch(err => {
      onError(err)
    })
}

function getObservations (onSuccess, onError) {
  firebaseApp
    .firestore()
    .collection('observations')
    .orderBy('date', 'desc')
    .get()
    .then(snapshot => {
      let observations = []
      snapshot.forEach(doc => {
        observations.push(doc.data())
      })
      onSuccess(observations)
    })
    .catch(err => {
      onError(err)
    })
}

function getTags (onSuccess, onError) {
  firebaseApp
    .firestore()
    .collection('tags')
    .get()
    .then(snapshot => {
      const tags = []
      snapshot.forEach(doc => {
        tags.push(doc.data())
      })
      let sortedTags = tags.sort((a, b) => {
        if (a.status !== 'installed' && b.status !== 'installed') {
          return 0
        }
        if (a.status !== 'installed') {
          return -1
        }
        if (b.status !== 'installed') {
          return 1
        }

        const dateA = new Date(
          `${a.events[getLastEventIndex(a)].date} ${
            a.events[getLastEventIndex(a)].time
          }`
        )
        const dateB = new Date(
          `${b.events[getLastEventIndex(b)].date} ${
            b.events[getLastEventIndex(b)].time
          }`
        )

        return dateA - dateB
      })
      sortedTags = sortedTags.reverse()
      onSuccess(sortedTags)
    })
    .catch(err => {
      onError(err)
    })
}

function getMember (uid, onSuccess, onError) {
  firebaseApp
    .firestore()
    .collection('users')
    .where('uid', '==', uid)
    .limit(1)
    .get()
    .then(snapshot => {
      snapshot.forEach(m => {
        onSuccess(m.data())
      })
    })
    .catch(err => {
      onError(err)
    })
}

function updateMember (memberInfos, onSuccess, onError) {
  // the email is updated to the auth backend by the function server
  const key = memberInfos.member_id
  delete memberInfos.observations
  delete memberInfos.tags
  delete memberInfos.statistics
  firebaseApp
    .firestore()
    .collection('users')
    .doc(key)
    .update(memberInfos)
    .then(onSuccess)
    .catch(onError)
}

function updateTag (tagInfos, onSuccess, onError) {
  // change it's event values, the status is updated by the function server
  const key = tagInfos.id
  firebaseApp
    .firestore()
    .collection('tags')
    .doc(key)
    .update(tagInfos)
    .then(onSuccess)
    .catch(onError)
}

function createObservation (observationInfos, onSuccess, onError) {
  // add a new observation
  const key = observationInfos.id
  firebaseApp
    .firestore()
    .collection('observations')
    .doc(key)
    .set(observationInfos)
    .then(onSuccess)
    .catch(onError)
}

export {
  firebaseApp,
  storage,
  allowOfflineMode,
  getSpecies,
  getTags,
  updateTag,
  getObservations,
  createObservation,
  getMember,
  updateMember
}
export default base
