import React, { Component } from 'react'

import ObservationCard from '../shared/ObservationCard'
import { Row, Button, Col } from 'antd'
import Title from 'antd/lib/typography/Title'

const lastObservations = (allSpecies, observations, loading) => {
  return observations.map((el, index) => {
    return (
      <ObservationCard
        {...el}
        allSpecies={allSpecies}
        key={index}
        isPublic={true}
        loading={loading}
      />
    )
  })
}

class PublicLastObservations extends Component {
  state = { nb_to_display: 8 }

  render () {
    const { allSpecies, loading } = this.props
    let { observations } = this.props

    observations = loading ? [1, 2, 3] : observations
    if (!loading && observations.length === 0) {
      return null
    }
    const newObservations = observations.slice(0, this.state.nb_to_display)

    return (
      <div>
        <Title level={2}>Dernières observations</Title>
        <p>Les informations détaillées sont disponibles sur demande.</p>
        <div className='site-card-wrapper'>
          <Row gutter={[16, 16]}>
            {lastObservations(allSpecies, newObservations, loading)}

            <Col xs={24}>
              <div className='text-center'>
                <Button
                  onClick={e => {
                    this.setState({
                      nb_to_display: this.state.nb_to_display + 8
                    })
                  }}
                >
                  Afficher plus d'observations
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default PublicLastObservations
