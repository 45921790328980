import React, { useState } from 'react'
import { Cascader, Form, Tabs, Table } from 'antd'

const { TabPane } = Tabs

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

function filter (inputValue, path) {
  return path.some(
    option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  )
}

const columns = [
  {
    title: 'Categorie',
    dataIndex: 'category',
    responsive: ['md']
  },
  {
    title: 'Nom scientifique',
    dataIndex: 'scientificName',
    responsive: ['md']
  },
  {
    title: 'Nom commun',
    dataIndex: 'vernacularName'
  }
]

const pelagosSpeciesScientificName = [
  'Baleanoptera physalus',
  'Caretta caretta',
  'Chelonia mydas',
  'Globicephala melas',
  'Physeter macrocephalus',
  'Stenella coeruleoalba'
]

export default function Species (props) {
  const [selectedSpecies, setSelectedSpecies] = useState('')
  const [selectedRow, setSelectedRow] = useState([])
  const { species, onSelect } = props

  const observableSpecies = species.filter(
    specie => specie.allow_observation === 'yes'
  )

  const pelagosSpecies = species.filter(specie =>
    pelagosSpeciesScientificName.includes(specie.scientific_name)
  )
  const rowsData = pelagosSpecies.map((s, index) => {
    return {
      key: index,
      scientificName: s.scientific_name,
      vernacularName: s.vernacular_name,
      category: s.category
    }
  })

  const getRowFromSpecies = species => {
    let res = []
    for (var index = 0; index < pelagosSpecies.length; index++) {
      if (species === pelagosSpecies[index].scientific_name) {
        res.push(index)
      }
    }
    return res
  }

  const grouped = groupBy(observableSpecies, 'category')

  let options = []
  for (let groupName in grouped) {
    let option = { value: groupName, label: groupName }
    let children = []
    for (let childIndex in grouped[groupName]) {
      const child = grouped[groupName][childIndex]
      children.push({
        value: child.scientific_name,
        label: `${child.vernacular_name} (${child.scientific_name})`
      })
    }
    option.children = children
    options.push(option)
  }

  return (
    <Form.Item
      name='species'
      rules={[
        {
          required: true,
          message: "Renseignez l'espèce' s'il vous plaît"
        }
      ]}
    >
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Sanctuaire Pelagos' key='1'>
          <Table
            pagination={false}
            columns={columns}
            dataSource={rowsData}
            rowSelection={{
              selectedRowKeys: selectedRow,
              type: 'radio',
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRow(selectedRowKeys)
                setSelectedSpecies([
                  selectedRows[0].category,
                  selectedRows[0].scientificName
                ])

                onSelect([
                  selectedRows[0].category,
                  selectedRows[0].scientificName
                ])
              }
            }}
          />
        </TabPane>

        <TabPane tab='Toutes les espèces' key='2'>
          <Cascader
            size='large'
            style={{ width: '100%', zIndex: 0 }}
            placeholder='Espèce observée'
            onChange={([category, species]) => {
              onSelect([category, species])
              setSelectedRow(getRowFromSpecies(species))

              setSelectedSpecies([category, species])
            }}
            options={options}
            showSearch={{ filter }}
            value={selectedSpecies}
          ></Cascader>
        </TabPane>
      </Tabs>
    </Form.Item>
  )
}
