import React from 'react'
import { Space } from 'antd'
import UploadImage from '../shared/UploadEventImage'

class FormProfilePicture extends React.Component {
  setImagePath = path => {
    let newMember = this.props.member
    newMember.profile_picture = path
    this.props.update(newMember)
  }

  render () {
    return (
      <div className='flex-container'>
        <Space direction='vertical' size='small'>
          <UploadImage
            domain='profile_pictures'
            setImagePath={this.setImagePath}
            legend='Image de profil'
            default={this.props.member.profile_picture}
          ></UploadImage>

          <p>Cliquez sur l'image pour changer </p>
        </Space>
      </div>
    )
  }
}

export default FormProfilePicture
