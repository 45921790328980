export default {
  token:
    'pk.eyJ1IjoiZnBtbSIsImEiOiJja2dhemJxamgwYzI3MzBzNTh6MGlzaWUxIn0.4sXya2ppTin4cW6gBHa2gw',
  styles: {
    londonCycle: 'mapbox://styles/mapbox/light-v9',
    light: 'mapbox://styles/mapbox/light-v9',
    dark: 'mapbox://styles/mapbox/dark-v9',
    basic: 'mapbox://styles/mapbox/basic-v9',
    outdoor: 'mapbox://styles/mapbox/outdoors-v10'
  }
}
