import React, { Component } from 'react'
import { Form, Space, Row, Col, Button } from 'antd'
import Title from 'antd/lib/typography/Title'

import Species from './Species'
import moment from 'moment'
import UploadImage from '../shared/UploadEventImage'
import ObservationInformation from './ObservationInformation'
import EventInformation from '../shared/EventInformation'

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo)
}

class NewObservationForm extends Component {
  state = {
    selectedSpecies: '',
    selectedCategory: '',
    imagePath: ''
  }

  onFinish = values => {
    const date = values.datetime.format('YYYY-MM-DD')
    const time = values.datetime.format('HH:mm')

    let observation = {}
    observation.id = `${date}-${time}-${Math.random()
      .toString(36)
      .substr(2, 9)}`
    observation.member_id = this.props.member.member_id

    // sometimes the value from the form is not the correct one...
    // we take the one from the state
    observation.species = this.state.selectedSpecies

    observation.group_size = values.group_size || 0
    observation.alive = values.alive || true
    observation.young_individuals_in_group = values.youngs
    observation.shell_size = values.shell_size || 0

    observation.date = date
    observation.time = time

    observation.latitude = values.latitude
    observation.longitude = values.longitude

    observation.free_description = values.free_description || 0

    observation.picture = this.state.picture_url || ''

    this.props.update(observation)
  }

  formRef = React.createRef()

  setFieldValues = values => {
    console.log('set field values', values)
    if (this.formRef && this.formRef.current) {
      const currentValues = this.formRef.current.getFieldsValue()
      this.formRef.current.setFieldsValue({ ...currentValues, ...values })
    }
  }

  setImagePath = path => {
    this.setState({ picture_url: path })
  }

  render () {
    const { species } = this.props

    return (
      <Form
        name='basic'
        ref={this.formRef}
        className='fullpage'
        onFinish={this.onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          datetime: moment(),
          alive: true
        }}
        style={{ width: '100%' }}
      >
        <Space direction='vertical' size='large' style={{ width: '100%' }}>
          <Space direction='vertical' size='small' style={{ width: '100%' }}>
            <Title level={2}>Spécimen</Title>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Species
                  species={species}
                  onSelect={([category, species]) => {
                    this.setFieldValues({
                      species: [category, species]
                    })
                    this.setState({
                      selectedCategory: category,
                      selectedSpecies: species
                    })
                  }}
                ></Species>
              </Col>
              <ObservationInformation
                category={this.state.selectedCategory}
              ></ObservationInformation>

              <Title level={3}>Media</Title>
              <Col xs={24}>
                <UploadImage
                  domain='observations'
                  setImagePath={this.setImagePath}
                  legend='Image du specimen'
                ></UploadImage>
              </Col>
              <EventInformation
                setFieldValues={this.setFieldValues}
                isCapture={false}
              ></EventInformation>
            </Row>
          </Space>
          <Button type='primary' htmlType='submit'>
            Ok
          </Button>
        </Space>
      </Form>
    )
  }
}

export default NewObservationForm
