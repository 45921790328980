import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import { Space } from 'antd'
import Navigation from '../shared/Navigation'
import ObservationsHistory from './ObservationsHistory'
class ObervationPage extends Component {
  navigation = () => {
    return <Navigation title={'Vos observations'}></Navigation>
  }

  render () {
    return (
      <BasePage myNavigation={this.navigation()} user={this.props.user}>
        <Space
          direction='vertical'
          size='large'
          className='fullpage'
          style={{ width: '100%' }}
        >
          <ObservationsHistory member={this.props.member} />
        </Space>
      </BasePage>
    )
  }
}

export default ObervationPage
