import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import { Space } from 'antd'
import Navigation from '../shared/Navigation'
import NewTagForm from './NewTagForm'
import { onlyAttributedTags } from '../../utils'
import { Redirect } from 'react-router-dom'

class NewTagPage extends Component {
  navigation = () => {
    return <Navigation title={'Nouveau tag posé'}></Navigation>
  }

  render () {
    if (this.props.redirect) {
      const redirect = this.props.redirect
      this.props.redirectDone()
      return <Redirect to={redirect} />
    }

    return (
      <BasePage myNavigation={this.navigation()} user={this.props.user}>
        <Space
          direction='vertical'
          size='large'
          className='fullpage'
          style={{ width: '100%' }}
        >
          <div className='text-center'>
            Vous pouvez remplir ce formulaire hors connexion, il se
            synchronisera à votre retour sur terre.
          </div>
          <NewTagForm
            update={this.props.update}
            selectedTagId={this.props.tagId}
            tags={onlyAttributedTags(this.props.member.tags)}
            species={this.props.species}
          ></NewTagForm>
        </Space>
      </BasePage>
    )
  }
}

export default NewTagPage
