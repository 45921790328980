import React, { Component, Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Upload, message, Progress } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { storage, TaskState } from '../../base'

class UploadImage extends Component {
  state = {
    loading: false,
    imageUrl: '',
    imageName: '',
    progress: 0
  }

  componentDidMount () {
    this.setState({ imageUrl: this.props.default })
  }

  uploadError = (path, error) => {
    message.error(`Erreur lors du téléchargement de ${path}.`)
    this.setState({ loading: false, progress: 0 })
  }

  onUploadSuccess = path => {
    message.success(`${path} téléchargé`)
    this.setState({ loading: false, progress: 0 })
  }

  handleChange = info => {
    console.log('info', info)
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === 'done') {
      this.uploadSuccess(info.file.name)
    } else if (info.file.status === 'error') {
      this.uploadError(info.file.name, '')
    }
  }

  beforeUpload = file => {
    const isImage = file.type.indexOf('image/') === 0
    if (!isImage) {
      message.error('You can only upload image file!')
    }

    // You can remove this validation if you want
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Image must smaller than 5MB!')
    }
    return isImage && isLt5M
  }

  uploadPaused = () => {
    // message.success(`Téléchargement en pause`)
  }

  uploadRunning = () => {
    // message.success('Téléchargement en cours')
  }

  uploadSuccess = () => {
    message.success('Téléchargement terminé')
    this.onUploadSuccess(this.state.imageName)
  }

  customUpload = async ({ onError, onSuccess, file }) => {
    // todo : firebase storage
    const thisBackup = this
    const metadata = {
      contentType: file.type
    }
    const imageName = uuidv4() //a unique name for the image
    const imagePath = `${this.props.domain}/${imageName}`
    this.setState({ imageName })
    const storageRef = await storage.ref(imagePath)
    try {
      const image = storageRef.put(file, metadata)
      image.on(
        'state_changed',
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          thisBackup.setState({ progress })
          switch (snapshot.state) {
            case TaskState.PAUSED: // or 'paused'
              thisBackup.uploadPaused()
              break
            case TaskState.RUNNING: // or 'running'
              thisBackup.uploadRunning()
              break
            case TaskState.SUCCESS:
              thisBackup.uploadSuccess()
              break
            default:
              console.log('unknown state', snapshot.state)
              break
          }
        },
        function (error) {
          // Handle unsuccessful uploads
          thisBackup.uploadError(file.name, error.message)
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          onSuccess()
          image.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            thisBackup.props.setImagePath(downloadURL)
            thisBackup.setState({
              imageUrl: downloadURL,
              loading: false
            })
            console.log('File available at', downloadURL)
          })
        }
      )
    } catch (e) {
      onError(e)
    }
  }

  render () {
    const { imageUrl } = this.state

    const uploadButton = (
      <Fragment>
        <Fragment>
          <PlusOutlined className='icon' />
          <br></br>
          <span className='ant-upload-text' style={{ marginRight: '5px' }}>
            {this.props.legend}
          </span>
        </Fragment>
      </Fragment>
    )

    return (
      <Upload
        name='avatar'
        listType='picture-card'
        className='avatar-uploader flex-container'
        showUploadList={false}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        customRequest={this.customUpload}
      >
        {this.state.progress > 0 ? (
          <Progress percent={this.state.progress} />
        ) : imageUrl ? (
          <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
        ) : (
          uploadButton
        )}
      </Upload>
    )
  }
}

export default UploadImage
