import React, { Component } from 'react'
import { Space, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import EventMaps from '../shared/EventsMap'
import ObservationCard from '../shared/ObservationCard'

class ObservationsHistory extends Component {
  render () {
    return (
      <section>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Title level={2}>Historique des observations</Title>
          <EventMaps observations={this.props.member.observations}></EventMaps>

          <Row gutter={[16, 16]}>
            {this.props.member.observations.map(o => {
              return (
                <ObservationCard
                  {...o}
                  member={this.props.member}
                  originalObservation={o}
                ></ObservationCard>
              )
            })}
          </Row>
        </Space>
      </section>
    )
  }
}

export default ObservationsHistory
