import React, { Component, Fragment } from 'react'
import { Card, Col } from 'antd'
import { requireImage, onlyEventsWithData } from '../../utils'
import { FaTag } from 'react-icons/fa'
import CardDateTime from './CardDatePos'
import CardSize from './CardSize'
import { Tabs } from 'antd'

const { TabPane } = Tabs

const tabForEvent = (event, index, isPublic) => {
  const {
    time,
    date,
    latitude,
    longitude,
    length,
    circumference,
    weight
  } = event
  return (
    <TabPane tab={`${date}-${time}`} key={index}>
      <div style={{ height: '95px' }} className='text-center'>
        <CardDateTime
          isPublic={isPublic}
          longitude={longitude}
          latitude={latitude}
          date={date}
          time={time}
        ></CardDateTime>
        <CardSize
          length={length}
          circumference={circumference}
          weight={weight}
        ></CardSize>
      </div>
    </TabPane>
  )
}

class RecaptureCard extends Component {
  // https://css-tricks.com/zooming-background-images/

  state = {
    currentEvent: 0
  }

  render () {
    const { species, isPublic } = this.props
    let { events } = this.props
    events = onlyEventsWithData(events)
    if (events.length === 0) {
      return null
    }
    return (
      <Col xs={24} md={12} lg={8} xl={6}>
        <Card
          className='recapture-card'
          title={
            <Fragment>
              <h3 className='text-center'>
                <FaTag className='icon' />
                {species}
              </h3>
              <div className='text-center'>
                {events.length === 1
                  ? '1 capture'
                  : `${events.length} captures`}
              </div>
            </Fragment>
          }
          cover={
            <div className='parent'>
              <div
                className='child'
                style={{
                  backgroundImage: `url(${
                    events.length > this.state.currentEvent
                      ? events[this.state.currentEvent].picture
                      : null
                  }), url(${requireImage(
                    events.length > this.state.currentEvent
                      ? events[this.state.currentEvent].picture
                      : null
                  )})`
                }}
              ></div>
            </div>
          }
        >
          <Tabs
            defaultActiveKey={`${events[0].date}-${events[0].time}`}
            onChange={tabKey => this.setState({ currentEvent: tabKey })}
          >
            {events.map((e, i) => tabForEvent(e, i, isPublic))}
          </Tabs>
        </Card>
      </Col>
    )
  }
}

export default RecaptureCard
