import React, { Component } from 'react'
import { Space, Descriptions } from 'antd'
import Title from 'antd/lib/typography/Title'

class FormProfileInfos extends Component {
  render () {
    const { member } = this.props
    return (
      <div>
        <Title level={2}>Informations personnelles</Title>
        <div className=''>
          <Space direction='vertical'>
            <div>
              Pour modifier ces informations, merci de contacter la fédération
              pour mettre à jour votre inscription.
            </div>
            <Descriptions>
              <Descriptions.Item label='Nom / prénom'>
                {member.name} {member.surname}
              </Descriptions.Item>
              <Descriptions.Item label="Numéro d'adhérent">
                {member.member_id}
              </Descriptions.Item>
              <Descriptions.Item label='Date de naissance'>
                {member.date_of_birth}
              </Descriptions.Item>
              <Descriptions.Item label='Genre'>
                {member.genre}
              </Descriptions.Item>
              <Descriptions.Item label='Nationalité'>
                {member.nationality}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </div>
      </div>
    )
  }
}

export default FormProfileInfos
