import React from 'react'
import { formatDateTime, formatLatLong } from '../../utils'
import { FaClock, FaMapMarkerAlt } from 'react-icons/fa'

const CardDateTime = ({ latitude, longitude, date, time, isPublic }) => {
  const pos = formatLatLong(latitude, longitude, isPublic)
  return (
    <div>
      <div>
        <p>
          <FaClock className='icon' />
          {formatDateTime(date, time)}
        </p>
        {pos ? (
          <p>
            <FaMapMarkerAlt className='icon' />
            {pos}
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default CardDateTime
