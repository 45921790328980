import React, { Component } from 'react'
import { Col, Form, Input, Switch, Row } from 'antd'
import Title from 'antd/lib/typography/Title'

import { coordsDecimalToHMS } from '../../utils'

// faire 2 composants (on rentre en HMS et on  sort en DD) :
// * un avec la latitude en  HMS // DD
// * un avec la longitude en HMS // DD

export class CoordsHMS extends Component {
  convertStateToCoordString = (h, m, checked) => {
    const segment = checked
      ? this.props.checkedValue
      : this.props.unCheckedValue
    return `${h}° ${m}′ ${segment}`
  }

  setAllState = (h, m, checked) => {
    this.setState({
      hours: h,
      minutes: m,
      checked: checked,
      coordsString: this.convertStateToCoordString(h, m, checked)
    })
  }

  state = {
    hours: this.props.hours,
    minutes: this.props.minutes,
    checked: this.props.checked,
    coordsString: this.convertStateToCoordString(
      this.props.hours,
      this.props.minutes,
      this.props.checked
    )
  }

  render () {
    return (
      <>
        <Row className='ant-space-item'>
          <Col span={8}>
            <Form.Item
              style={{ width: '50%', display: 'inline-block' }}
              name={`${this.props.category}-hours`}
              rules={[
                {
                  required: true,
                  message: "Renseignez la valeur des degrés s'il vous plaît"
                }
              ]}
            >
              <Input
                value={this.state.hours}
                size='large'
                placeholder={this.props.defaultHour}
                onChange={e => {
                  this.setAllState(
                    e.target.value,
                    this.state.minutes,
                    this.state.checked
                  )
                }}
                onBlur={e => {
                  this.props.onChange(this.state.coordsString)
                }}
              />
            </Form.Item>
            <span
              style={{
                marginLeft: '7px',
                display: 'inline',
                marginTop: '-0.5em'
              }}
            >
              Degrés
            </span>
          </Col>
          <Col span={8}>
            <Form.Item
              style={{ width: '50%', display: 'inline-block' }}
              name={`${this.props.category}-minutes`}
              rules={[
                {
                  required: true,
                  message: "Renseignez la valeur des minutes s'il vous plaît"
                }
              ]}
            >
              <Input
                value={this.state.minutes}
                size='large'
                placeholder={this.props.defaultMinute}
                onChange={e => {
                  this.setAllState(
                    this.state.hours,
                    e.target.value,
                    this.state.checked
                  )
                }}
                onBlur={e => {
                  this.props.onChange(this.state.coordsString)
                }}
              />
            </Form.Item>
            <span style={{ marginLeft: '7px', marginTop: '-0.5em' }}>
              Minutes
            </span>
          </Col>
          <Col span={8}>
            <Form.Item name='checkbox'>
              <Switch
                checkedChildren={this.props.checkedValue}
                unCheckedChildren={this.props.unCheckedValue}
                defaultChecked
                checked={this.state.checked}
                onChange={isChecked => {
                  this.setAllState(
                    this.state.hours,
                    this.state.minutes,
                    isChecked
                  )
                  this.props.onChange(
                    this.convertStateToCoordString(
                      this.state.hours,
                      this.state.minutes,
                      isChecked
                    )
                  )
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
}

export class LatLongHMS extends Component {
  state = {
    latitudeHMS: coordsDecimalToHMS(
      this.props.latitude,
      this.props.longitude
    )[0],
    longitudeHMS: coordsDecimalToHMS(
      this.props.latitude,
      this.props.longitude
    )[1]
  }

  isCoordsOk = coords => {
    if (
      coords.includes('undefined') ||
      coords.includes('NaN') ||
      coords.includes('null')
    ) {
      return false
    }
    return true
  }

  getHoursFromHMS = coords => {
    if (!this.isCoordsOk(coords)) {
      return null
    }
    return coords.split('°')[0].trim()
  }

  getMinutesFromHMS = coords => {
    if (!this.isCoordsOk(coords)) {
      return null
    }

    return coords
      .split('°')[1]
      .split('′')[0]
      .trim()
  }

  getQuadrantFromHMS = (coords, checkedValue) => {
    if (!this.isCoordsOk(coords)) {
      return null
    }

    const q = coords
      .split('°')[1]
      .split('′')[1]
      .trim()
    return q === checkedValue
  }

  handleChange = (lat, long) => {
    this.setState({
      latitudeHMS: lat,
      longitudeHMS: long
    })
    if (this.isCoordsOk(lat) && this.isCoordsOk(long)) {
      this.props.onChange(lat, long)
    }
  }

  render () {
    return (
      <>
        <Row className='ant-space-item'>
          <Title level={3}>Latitude</Title>
          <CoordsHMS
            category='latitude'
            key='hms-latitude'
            checkedValue='N'
            unCheckedValue='S'
            defaultHour='43'
            defaultMinute='44'
            hours={this.getHoursFromHMS(this.state.latitudeHMS)}
            minutes={this.getMinutesFromHMS(this.state.latitudeHMS)}
            checked={this.getQuadrantFromHMS(this.state.latitudeHMS, 'N')}
            onChange={coord =>
              this.handleChange(coord, this.state.longitudeHMS)
            }
          />
        </Row>

        <Row className='ant-space-item'>
          <Title level={3}>Longitude</Title>
          <CoordsHMS
            category='longitude'
            key='hms-longitude'
            checkedValue='E'
            unCheckedValue='W'
            defaultHour='7'
            defaultMinute='25'
            hours={this.getHoursFromHMS(this.state.longitudeHMS)}
            minutes={this.getMinutesFromHMS(this.state.longitudeHMS)}
            checked={this.getQuadrantFromHMS(this.state.longitudeHMS, 'E')}
            onChange={coord => this.handleChange(this.state.latitudeHMS, coord)}
          />
        </Row>

        <Row className='ant-space-item' style={{ marginBottom: '1em' }}>
          Position considérée : {this.state.latitudeHMS} -{' '}
          {this.state.longitudeHMS} (soit en décimal :{' '}
          {this.props.latitude.toFixed(2)}, {this.props.longitude.toFixed(2)})
        </Row>
      </>
    )
  }
}

export class LatLongDD extends Component {
  state = {
    latitudeDD: this.props.latitude,
    longitudeDD: this.props.longitude
  }

  render () {
    return (
      <>
        <Col xs={24} md={8} style={{ display: 'none' }}>
          <Form.Item
            name='latitudeDD'
            rules={[
              {
                required: true,
                message: "Renseignez la latitude s'il vous plaît"
              }
            ]}
          >
            <Input
              value={this.state.latitude}
              size='large'
              placeholder='Latitude DD'
              onChange={e => {
                this.setState({ latitudeDD: e.target.value })
              }}
              onBlur={e => {
                this.props.onChange(
                  this.state.latitudeDD,
                  this.state.longitudeDD
                )
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} style={{ display: 'none' }}>
          <Form.Item
            name='longitudeDD'
            rules={[
              {
                required: true,
                message: "Renseignez la longitude s'il vous plaît"
              }
            ]}
          >
            <Input
              value={this.state.longitude}
              size='large'
              placeholder='Longitude DD'
              onChange={e => {
                this.setState({ longitudeDD: e.target.value })
              }}
              onBlur={e => {
                this.props.onChange(
                  this.state.latitudeDD,
                  this.state.longitudeDD
                )
              }}
            />
          </Form.Item>
        </Col>
      </>
    )
  }
}

export class LatLongResult extends Component {
  state = {
    latitude: null,
    longitude: null
  }

  render () {
    return (
      <>
        <Col xs={24} md={8} style={{ display: 'none' }}>
          <Form.Item
            name='latitude'
            rules={[
              {
                required: true,
                message: "Renseignez la latitude s'il vous plaît"
              }
            ]}
          >
            <Input
              value={this.state.latitude}
              size='large'
              placeholder='Latitude'
              onChange={e => {
                this.setState({ latitude: e.target.value })
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} style={{ display: 'none' }}>
          <Form.Item
            name='longitude'
            rules={[
              {
                required: true,
                message: "Renseignez la longitude s'il vous plaît"
              }
            ]}
          >
            <Input
              value={this.state.longitude}
              size='large'
              placeholder='Longitude'
              onChange={e => {
                this.setState({ longitude: e.target.value })
              }}
            />
          </Form.Item>
        </Col>
      </>
    )
  }
}
