import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import { Form, Input, Button, Alert } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { firebaseApp } from '../../base'
import LoginButton from '../shared/LoginButton'

const SUCCESS_MESSAGE =
  'Suivez les instructions du mail pour mettre à jour le mot de passe de votre compte.'

class ForgotPassword extends Component {
  state = { error: '', success: '' }

  onFinish = values => {
    firebaseApp
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(auth => {
        console.log('auth success', auth)
        this.setState({ error: '', success: SUCCESS_MESSAGE })
        this.setState({})
      })
      .catch(error => {
        console.error('error', error)
        this.setState({ error: error.message, success: '' })
      })
  }

  render () {
    return (
      <BasePage>
        <div className='flex-container' style={{ height: '75vh' }}>
          {this.state.error !== '' && (
            <Alert
              message='Erreur'
              description={this.state.error}
              type='error'
              showIcon
              style={{ marginBottom: '2em' }}
            />
          )}

          {this.state.success !== '' && (
            <Alert
              message='Envoi effectué'
              description={this.state.success}
              type='info'
              showIcon
              style={{ marginBottom: '2em' }}
            />
          )}

          <Form
            name='normal_login'
            className='login-form'
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <div style={{ marginBottom: '20px' }}>
              Entrez votre email pour recevoir un mail permettant de mettre à
              jour votre mot de passe.
            </div>

            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: "Entrez votre email s'il vous plaît"
                  // type: 'email'
                }
              ]}
            >
              <Input
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Email'
              />
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
                style={{ width: '100%' }}
              >
                Go !
              </Button>
            </Form.Item>

            {this.state.success !== '' && (
              <LoginButton
                className='login-form-button'
                style={{ width: '100%' }}
              >
                Se connecter
              </LoginButton>
            )}
          </Form>
        </div>
      </BasePage>
    )
  }
}

export default ForgotPassword
