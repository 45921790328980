import React, { Component, Fragment } from 'react'
import { Button, Modal } from 'antd'
import { PhoneOutlined } from '@ant-design/icons'

function info () {
  Modal.info({
    title: "Numéros à appeler en cas d'urgences",
    content: (
      <Fragment>
        <p>
          Si vous observez un animal en danger, voilà les numéros à contacter.
        </p>

        <div>
          <ul>
            <li>
              Fédération : <a href='tel:0640101808'>0640101808</a>
            </li>
            <li>
              Musée Océanographique (tortues) :
              <a href='tel:+377 93 15 36 00'>+377 93 15 36 00</a>
            </li>
          </ul>
        </div>
      </Fragment>
    ),
    onOk () {}
  })
}

class EmergencyNumber extends Component {
  render () {
    return (
      <Button onClick={info} type='primary'>
        <PhoneOutlined></PhoneOutlined>
      </Button>
    )
  }
}

export default EmergencyNumber
