import React, { Component, Fragment } from 'react'
import { Card, Col, Skeleton, message, Space } from 'antd'
import { requireImage, humanizeSpecies } from '../../utils'
import { FaBinoculars } from 'react-icons/fa'
import CardDateTime from './CardDatePos'
import EventImage from '../shared/UploadEventImage'
import { createObservation } from '../../base'

const ImageCover = ({ picture }) => (
  <div className='parent'>
    <div
      className='child'
      style={{
        backgroundImage: `url(${picture}), url(${requireImage(picture)})`
      }}
    ></div>
  </div>
)

const AddImageCover = ({ update }) => (
  <div style={{ height: '250px' }} className='flex-container'>
    <EventImage
      domain='observations'
      legend='Ajouter une image'
      setImagePath={update}
    />
  </div>
)

class ObservationCard extends Component {
  // https://css-tricks.com/zooming-background-images/

  state = {
    picture: this.props.picture
  }

  updateObservation = path => {
    this.setState({ picture: path })

    let observation = this.props.originalObservation
    observation.picture = this.state.picture || ''

    createObservation(
      observation,
      () => message.success("Image ajoutée à l'observation"),
      error => message.error(error.message)
    )
  }

  render () {
    const {
      loading,
      species,
      isPublic, // display GPS or not
      date,
      time,
      latitude,
      longitude,
      member_id,
      member
    } = this.props

    if (this.props.public === 'no') {
      return null
    }

    const humanSpecies = humanizeSpecies(this.props.allSpecies, species)

    return (
      <Col xs={24} md={12} lg={8} xl={6}>
        <Skeleton loading={loading} active>
          <Card
            title={
              <Fragment>
                <h3 className='text-center'>{species}</h3>

                {humanSpecies ? (
                  <p className='text-center text-muted'> {humanSpecies} </p>
                ) : null}

                <p className='text-center'>
                  <Space>
                    <FaBinoculars className='icon' />
                    par {member_id}
                  </Space>
                </p>
              </Fragment>
            }
            cover={
              member &&
              member.member_id === member_id &&
              this.state.picture === '' ? (
                <AddImageCover update={this.updateObservation}></AddImageCover>
              ) : (
                <ImageCover picture={this.state.picture}></ImageCover>
              )
            }
          >
            <div className='text-center'>
              <CardDateTime
                isPublic={isPublic}
                longitude={longitude}
                latitude={latitude}
                date={date}
                time={time}
              ></CardDateTime>
            </div>
          </Card>
        </Skeleton>
      </Col>
    )
  }
}

export default ObservationCard
