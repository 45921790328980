import React from 'react'
import Title from 'antd/lib/typography/Title'
import { Statistic, Row, Col, Card, Skeleton } from 'antd'

const Statistics = ({ member, loading }) => {
  const statistics = member.statistics || {
    remain: 0,
    lost: 0,
    installed: 0,
    observations: 0
  }
  return (
    <section>
      <Title level={2}>Statistiques </Title>

      <Row gutter={[16, 16]}>
        <Col xs={12} md={6}>
          <Skeleton loading={loading} active>
            <Card>
              <Statistic
                title={statistics.remain < 2 ? 'Tag restant' : 'Tags restants'}
                value={statistics.remain}
              />
            </Card>
          </Skeleton>
        </Col>
        <Col xs={12} md={6}>
          <Skeleton loading={loading} active>
            <Card>
              <Statistic
                value={statistics.lost}
                title={statistics.lost < 2 ? 'Tag perdu' : 'Tags perdus'}
              />
            </Card>
          </Skeleton>
        </Col>
        <Col xs={12} md={6}>
          <Skeleton loading={loading} active>
            <Card>
              <Statistic
                value={statistics.installed}
                title={statistics.installed < 2 ? 'Tag posé' : 'Tags posés'}
              />
            </Card>
          </Skeleton>
        </Col>
        <Col xs={12} md={6}>
          <Skeleton loading={loading} active>
            <Card>
              <Statistic
                value={statistics.observations}
                title={
                  statistics.observations < 2 ? 'Observation' : 'Observations'
                }
              />
            </Card>
          </Skeleton>
        </Col>
      </Row>
    </section>
  )
}

export default Statistics
