import React, { Component } from 'react'
import Title from 'antd/lib/typography/Title'
import PublicLastObservations from './PublicLastObservations'
import PublicLastCaptures from './PublicLastCaptures'
import BasePage from '../shared/BasePage'

class PublicPage extends Component {
  render () {
    return (
      <BasePage>
        <Title level={1}>Monaco Tagging Application</Title>
        <PublicLastObservations
          allSpecies={this.props.species}
          observations={this.props.observations}
          loading={this.props.loadingObservations}
        ></PublicLastObservations>
        <PublicLastCaptures
          allSpecies={this.props.species}
          captures={this.props.tags}
          loading={this.props.loadingTags}
        ></PublicLastCaptures>
      </BasePage>
    )
  }
}

export default PublicPage
