import React, { Component } from 'react'
import Title from 'antd/lib/typography/Title'

import TagsList from './TagsList'
import { Space } from 'antd'

class TagsManagement extends Component {
  render () {
    return (
      <section>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Title level={2}>Inventaire des tags</Title>
          <div>
            <p>
              Vous pouvez ici les déclarer perdus ou posés les tags qui vous ont
              été attribués.
            </p>
          </div>
          <div className='flex-container'>
            <TagsList
              update={this.props.update}
              tags={this.props.tags}
            ></TagsList>
          </div>
        </Space>
      </section>
    )
  }
}

export default TagsManagement
