import React, { useState } from 'react'
import Title from 'antd/lib/typography/Title'
import CaptureCard from '../shared/CaptureCard'
import { Row, Button, Empty } from 'antd'
import { Redirect, Link } from 'react-router-dom'
import { onlyInstalledTags } from '../../utils'

function EmptyTags ({ loading, tags }) {
  if (loading) {
    return null
  }

  if (tags.length > 0) {
    return null
  }

  return (
    <Empty
      image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
      imageStyle={{
        height: 60
      }}
      description={<span>Aucun tag posé</span>}
    >
      <Link to='/new-tag'>
        <Button key='2' type='primary' id='new-tag'>
          Nouveau Marquage
        </Button>
      </Link>
      ,
    </Empty>
  )
}

const MemberTags = ({ allSpecies, member, loading }) => {
  const [redirect, setRedirect] = useState(false)
  const tags = loading ? [1, 1, 1] : onlyInstalledTags(member.tags || [])

  if (redirect) {
    return <Redirect push to='/tags' />
  }
  return (
    <section>
      <Title level={2}>Tags</Title>

      <EmptyTags tags={tags} loading={loading}></EmptyTags>
      <div className='site-card-wrapper'>
        <Row gutter={[16, 16]}>
          {tags.map((tag, index) => {
            return (
              <CaptureCard
                allSpecies={allSpecies}
                loading={loading}
                key={index}
                {...tag}
                tag={tag}
                member={member}
                isPublic={false}
              ></CaptureCard>
            )
          })}
        </Row>
      </div>
      <div className='flex-container'>
        <Button onClick={() => setRedirect(true)}>Vos tags</Button>
      </div>
    </section>
  )
}

export default MemberTags
