import React, { useEffect, Component } from 'react'
import './App.css'
import * as Sentry from '@sentry/react'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom'

import LoginPage from './components/LoginPage'
import NotFound from './components/NotFound'
import ForgotPassword from './components/ForgotPasswordPage'
import MemberPage from './components/MemberPage'
import ProfilePage from './components/ProfilePage'
import TagsPage from './components/TagsPage'
import PublicPage from './components/Publicpage'
import ObservationPage from './components/ObservationPage'
import NewTagPage from './components/NewTagPage'
import NewObservationPage from './components/NewObservationPage'
import EventMapPage from './components/EventMapPage'

import {
  firebaseApp,
  getSpecies,
  getTags,
  updateTag,
  getObservations,
  createObservation,
  getMember,
  updateMember
} from './base'
import { addFieldsToMember } from './utils'
import { message } from 'antd'

const ProtectedRoute = originalProps => {
  const { component: Component, ...state } = originalProps

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Route
      render={props => {
        return state.user && state.user.uid ? (
          <Component {...props} {...state} />
        ) : (
          <Redirect to='/login' />
        )
      }}
    />
  )
}

const AutoLogin = originalProps => {
  const { component: Component, ...state } = originalProps

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Route
      render={props => {
        return state.user && state.user.uid ? (
          <Redirect to='/member' />
        ) : (
          <Component {...state} {...props} />
        )
      }}
    />
  )
}

class App extends Component {
  state = {
    user: {},
    member: {},
    species: [],
    tags: [],
    observations: [],
    loadingTags: true,
    loadingObservations: true,
    loadingMember: true,
    redirect: ''
  }

  redirectDone = () => {
    const newState = this.state
    newState.redirect = ''
    this.setState(newState)
  }

  onError = error => {
    message.error(error.message)
    console.log(error.message)
    // TODO : mettre un sentry si on a une erreur
  }

  onUpdateStateSuccess = newState => {
    return () => {
      this.setState(newState)
      message.success('Mise à jour réussie')
    }
  }

  updateMemberState = newMemberState => {
    const newState = this.state
    newState.member = newMemberState
    updateMember(
      newMemberState,
      this.onUpdateStateSuccess(newState),
      this.onError
    )
  }

  updateTagState = tagState => {
    const newState = this.state
    const tags = []
    for (const tagIndex in newState.tags) {
      let tag = newState.tags[tagIndex]
      if (tag.id === tagState.id) {
        tag = tagState
      }
      tags.push(tag)
    }
    newState.tags = tags
    updateTag(
      tagState,
      () => {
        message.success('Tag synchronisée !')
      },
      this.onError
    )

    // See the TODO for the createObsevation
    this.onUpdateStateSuccess(newState)
    const newAppState = this.state
    newAppState.redirect = '/member'
    this.setState(newAppState)
    message.success('Tag posé !')
  }

  updateObservationState = observationState => {
    const newState = this.state
    newState.observations.push(observationState)
    createObservation(
      observationState,
      () => {
        message.success(
          `Observation de ${observationState.group_size} ${observationState.species} le ${observationState.date} à ${observationState.time} synchronisée !`
        )
      },
      this.onError
    )

    // TODO : this can add some issues...
    // We redirect to the homepage but we can't garantee that the firestore
    // request will correctly terminate. This can happen in
    // some non identified corner cases where the species is "1".

    // We should keep the data somewhere in localstorage and remove it
    // from the localstorage to be sure.
    const newAppState = this.state
    newAppState.redirect = '/member'
    this.setState(newAppState)
    message.success('Observation effectuée !')
  }

  loginMember = user => {
    Sentry.configureScope(function (scope) {
      scope.setUser({ email: user.email })
    })

    const newState = this.state
    newState.user = user
    getMember(
      user.uid,
      member => {
        member = addFieldsToMember(
          member,
          this.state.observations,
          this.state.tags
        )
        const newState = this.state
        newState.member = member
        newState.loadingMember = false
        this.setState(newState)
      },
      error => {
        message.error(error.message)
      }
    )
    this.setState(newState)
  }

  logoutMember = () => {
    const newState = this.state
    newState.user = {}
    newState.member = {}
    this.setState(newState)
  }

  handleAuth = () => {
    const thisHandle = this
    firebaseApp.auth().onAuthStateChanged(function (user) {
      if (user && user.uid) {
        thisHandle.loginMember(user)
      } else {
        thisHandle.logoutMember()
      }
    })
  }

  handleData = () => {
    getSpecies(
      species => {
        const newState = this.state
        newState.species = species
        this.setState(newState)
      },
      error => {
        console.log(error)
      }
    )
    getObservations(
      observations => {
        const newState = this.state
        if (Object.keys(this.state.member).length > 0) {
          const member = addFieldsToMember(
            this.state.member,
            this.state.observations,
            this.state.tags
          )
          newState.member = member
        }
        newState.observations = observations
        newState.loadingObservations = false
        this.setState(newState)
      },
      error => {
        console.log(error)
      }
    )
    getTags(
      tags => {
        const newState = this.state
        if (Object.keys(this.state.member).length > 0) {
          const member = addFieldsToMember(
            this.state.member,
            this.state.observations,
            this.state.tags
          )
          newState.member = member
        }
        newState.tags = tags
        newState.loadingTags = false
        this.setState(newState)
      },
      error => {
        console.log(error)
      }
    )
  }

  componentWillMount () {
    this.handleAuth()
    this.handleData()
  }

  render () {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path='/'
            render={props => {
              return (
                <PublicPage
                  {...props}
                  tags={this.state.tags}
                  species={this.state.species}
                  observations={this.state.observations}
                  loadingObservations={this.state.loadingObservations}
                  loadingTags={this.state.loadingTags}
                />
              )
            }}
          />
          <Route
            exact
            path='/carte-evenements'
            render={props => {
              return (
                <EventMapPage
                  {...props}
                  tags={this.state.tags}
                  species={this.state.species}
                  observations={this.state.observations}
                  loadingObservations={this.state.loadingObservations}
                  loadingTags={this.state.loadingTags}
                />
              )
            }}
          />

          <AutoLogin
            exact
            path='/login'
            component={LoginPage}
            {...this.state}
          />
          <AutoLogin
            exact
            path='/forgot-password'
            component={ForgotPassword}
            {...this.state}
          />
          <ProtectedRoute
            path='/member'
            component={MemberPage}
            {...this.state}
          />
          <ProtectedRoute
            path='/profile'
            component={ProfilePage}
            update={this.updateMemberState}
            {...this.state}
          />
          <ProtectedRoute
            path='/tags'
            component={TagsPage}
            {...this.state}
            update={this.updateTagState}
          />
          <ProtectedRoute
            path='/observations'
            update={this.updateObservationState}
            component={ObservationPage}
            {...this.state}
          />
          <ProtectedRoute
            path='/new-tag'
            update={this.updateTagState}
            component={NewTagPage}
            {...this.state}
            redirectDone={this.redirectDone}
          />
          <ProtectedRoute
            path='/new-observation'
            update={this.updateObservationState}
            component={NewObservationPage}
            redirectDone={this.redirectDone}
            {...this.state}
          />

          <Route component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

export default App
