import React, { Component } from 'react'
import BasePage from '../shared/BasePage'
import Greatings from './Greatings'
import Statistics from './Statistics'
import MemberTags from './MemberTags'
import MemberObservations from './MemberObservations'
import Navigation from '../shared/Navigation'
import { Space } from 'antd'

class MemberPage extends Component {
  greatings = () => {
    return <Greatings member={this.props.member} />
  }

  navigation = () => {
    return <Navigation title={this.greatings()} backIcon={false}></Navigation>
  }

  render () {
    return (
      <BasePage myNavigation={this.navigation()} user={this.props.user}>
        <Space
          direction='vertical'
          size='large'
          className='fullpage'
          style={{ width: '100%' }}
        >
          <Statistics
            member={this.props.member}
            loading={
              this.props.loadingTags ||
              this.props.loadingObservations ||
              this.props.loadingMember
            }
          />
          {/* todo : change le tags et le observations dans les classes */}
          <MemberTags
            allSpecies={this.props.species}
            member={this.props.member}
            loading={this.props.loadingTags}
          />
          <MemberObservations
            allSpecies={this.props.species}
            member={this.props.member}
            loading={this.props.loadingObservations}
          />
        </Space>
      </BasePage>
    )
  }
}

export default MemberPage
